import React, { useMemo } from 'react';
import { KobrosLogoWhite } from '@fingo/lib/assets';
import { useIsMobile, useSelectedCompany, useGetUser } from '@fingo/lib/hooks';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { GET_INVOICE_QUANTITIES } from '@fingo/lib/graphql';
import { useQuery } from '@apollo/client';
import dayjs from '@fingo/lib/config/dayjs';
import { hasCollectionAvailable } from '@fingo/lib/helpers/plans';
import NewProductBanner from './NewProductBanner';
import { useCountFactoring } from '../../../hooks';

const KobrosNewProductBanner = () => {
  const isMoblie = useIsMobile();
  const history = useHistory();
  const user = useGetUser();
  const textColor = 'white';
  const color = 'secondary.main';

  const selectedCompany = useSelectedCompany();
  const expirationDateGte = dayjs().format('YYYY-MM-DD');
  const expirationDateLte = dayjs().add(1, 'week').format('YYYY-MM-DD');

  const { cessions } = useCountFactoring();

  const userHasActiveCollectionPlan = useMemo(
    () => hasCollectionAvailable(user),
    [user],
  );

  const { data } = useQuery(GET_INVOICE_QUANTITIES, {
    variables: {
      companyId: selectedCompany?.id,
      dateExpiration_Lt: expirationDateLte,
      dateExpiration_Gt: expirationDateGte,
      dteType_Code_In: ['33', '34'],
    },
    skip: !selectedCompany,
  });
  const invoices = data?.invoices.totalCount;
  const shouldShowBanner = cessions > 0 && invoices > 0 && !userHasActiveCollectionPlan;

  return !isMoblie && shouldShowBanner ? (
    <NewProductBanner
      title="El nuevo software de cobranza de Fingo"
      textColor={textColor}
      color={color}
      availableUrls={[
        '/app/sales/factoring/available',
        '/app/sales',
        '/app/sales/factoring',
      ]}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={15}
        paddingY={1}
        marginY="auto"
        width="100%"
        marginX={1}
      >
        <Box
          component="img"
          src={KobrosLogoWhite}
          alt=""
          sx={{
            maxHeight: 40,
            maxWidth: 'auto',
            objectFit: 'contain',
          }}
        />
        <Box textAlign="center">
          <Typography
            sx={{ fontSize: { lg: 14, md: 13 } }}
            component="span"
            color="common.white"
            fontWeight="Bold"
          >
            Tienes {invoices} facturas por cobrar.{' '}
          </Typography>
          <Typography
            sx={{ fontSize: { lg: 14, md: 13 } }}
            component="span"
            color="common.white"
          >
            Descubre koBros, la herramienta de cobranza de Fingo.
          </Typography>
        </Box>
        <Button
          id="know-kobros-banner-button"
          variant="outlined"
          size="small"
          sx={{
            backgroundColor: color,
            paddingY: 0,
            borderColor: textColor,
            color: textColor,
            height: 33,
            '&:hover': {
              color,
              borderColor: textColor,
              backgroundColor: textColor,
            },
          }}
          onClick={() => history.push('/app/sales/collection/home')}
        >
          <Typography sx={{ fontSize: { lg: 14, md: 12 } }} fontWeight="Bold">
            Explorar koBros
          </Typography>
        </Button>
      </Stack>
    </NewProductBanner>
  ) : null;
};

export default KobrosNewProductBanner;
