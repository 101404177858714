import React, { useMemo } from 'react';
import {
  LoggedInNavItems,
  ProfileNavItem,
} from '@fingo/lib/components/layout/navbar';
import Stack from '@mui/material/Stack';
import { HelpCenterLink } from '@fingo/lib/components/links';
import { useGetUser } from '@fingo/lib/hooks';
import SelectCompanyNavItem from '@fingo/lib/components/company/SelectCompanyNavItem';
import Notifications from '../../../views/App/notifications/NotificationMenu';
import ShoppingCart from '../../shopping/shoppingCart';
import { useFingoRoutes, useShowingTyC } from '../../../hooks';

const LoggedInDesktopNavItems = () => {
  const routes = useFingoRoutes().filter(({ showInNavbar }) => showInNavbar);
  const currentUser = useGetUser();
  const profileMissing = currentUser?.hasProfileInfoMissing || 0;
  const credentialsMissing = currentUser?.selectedCompany?.hasMissingCredentials || 0;
  const showTyc = useShowingTyC();
  return useMemo(
    () => (
      <Stack
        width="100%"
        direction="row"
        spacing={3}
        height="100%"
        alignItems="center"
        justifyContent="space-around"
        ml={4}
      >
        <LoggedInNavItems routes={routes} />
        <HelpCenterLink />
        <SelectCompanyNavItem />
        <Stack direction="row" spacing={1} alignItems="center">
          {!showTyc && (
            <>
              <Notifications />
              <ShoppingCart />
              <ProfileNavItem
                credentialsMissing={credentialsMissing}
                profileMissing={profileMissing}
              />
            </>
          )}
        </Stack>
      </Stack>
    ),
    [routes, currentUser, profileMissing, credentialsMissing, showTyc],
  );
};

export default LoggedInDesktopNavItems;
