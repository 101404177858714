import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const FeatureList = ({
  features,
  iconColor = 'primary',
  titleColor = 'primary',
  subtitleColor = 'textSecondary',
  ...props
}) => (
  <Grid
    container
    spacing={3}
    justifyContent="center"
    alignItems="center"
    {...props}
  >
    {features.map(({ id, icon, title, subtitle }) => (
      <Grid item mobile={3} key={id} textAlign="center">
        <Box color={iconColor}>
          {React.cloneElement(icon, { fontSize: 'large' })}
        </Box>
        <Typography
          fontSize={{ mobile: 12, tablet: 18 }}
          color={titleColor}
          mt={1}
          fontWeight="bold"
        >
          {title}
        </Typography>
        <Typography
          fontSize={{ mobile: 8, tablet: 14 }}
          color={subtitleColor}
          mt={1}
        >
          {subtitle}
        </Typography>
      </Grid>
    ))}
  </Grid>
);

FeatureList.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      icon: PropTypes.element.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
    }),
  ).isRequired,
  iconColor: PropTypes.string,
  titleColor: PropTypes.string,
  subtitleColor: PropTypes.string,
};

FeatureList.defaultProps = {
  iconColor: 'primary.main',
  titleColor: 'primary',
  subtitleColor: 'textSecondary',
};

export default FeatureList;
