import React from 'react';
import FingoNavbar from '@fingo/lib/components/layout/FingoNavbar';
import CountryNavItem from '@fingo/lib/components/layout/navbar/CountryNavItem';
import CurrencyNavItem from '@fingo/lib/components/layout/navbar/CurrencyNavItem';
import LogoNavItem from '@fingo/lib/components/layout/navbar/LogoNavItem';
import LoggedOutNavItems from '@fingo/lib/components/layout/navbar/LoggedOutNavItems';
import useDynamicFingoLogo from '@fingo/lib/hooks/useDynamicFingoLogo';
import useIsLogged from '@fingo/lib/hooks/useIsLogged';
import useGetUser from '@fingo/lib/hooks/useGetUser';
import Stack from '@mui/material/Stack';
import LoggedInDesktopNavItems from './LoggedInDesktopNavItems';
import KobrosNewProductBanner from './KobrosBanner';

const DesktopNavbar = () => {
  const isLogged = useIsLogged();
  const user = useGetUser();
  const fingoLogo = useDynamicFingoLogo();

  return (
    <>
      <FingoNavbar>
        <LogoNavItem logo={fingoLogo} isLogged={isLogged} id="nav-logo" />
        {isLogged && user && <LoggedInDesktopNavItems />}
        {!isLogged && <LoggedOutNavItems title="Comencemos" />}
        <Stack direction="row" spacing={3}>
          <CountryNavItem />
          {isLogged && <CurrencyNavItem />}
        </Stack>
      </FingoNavbar>
      {isLogged && <KobrosNewProductBanner />}
    </>
  );
};

export default DesktopNavbar;
