import styled from '@mui/material/styles/styled';
import TextField from '@mui/material/TextField';

const UnderlineColorTextField = styled(
  TextField,
  { shouldForwardProp: (prop) => !['underlineColor'].includes(prop) },
)(({ underlineColor }) => ({
  '& .MuiInput-root': {
    '&::before, &::after, &:hover:before': {
      borderBottomColor: `${underlineColor} !important`,
    },
  },
}));

export default UnderlineColorTextField;
